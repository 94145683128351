import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as encode from 'nodejs-base64-encode';
import styled from 'styled-components';
import { Icon } from '@iconify/react';

// Components
import Text from '../../../components/common/Text';
import SubjectAccordion from './SubjectAccordion';
import ElectiveSubject from './ElectiveSubject';

// Material UI
import RadioButtonUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import CheckCircle from '@mui/icons-material/CheckCircle';
import LinearProgress from '@mui/material/LinearProgress';
import Error from '@mui/icons-material/Error';
import Button from '@mui/material/Button';

// Assets
import surveyImage from '../../../assets/media/aden/survey.png';

// Hooks
import useStudentStatus from '../../campus/hooks/useStundetStatus';
import useWindowWidth from '../../../hooks/useWindowWidth';

const SubjectFormativeModules = (props) => {
    const { modules, origin, demo, resetData } = props;

    const params = useParams();
    const history = useHistory();
    const screenWidth = useWindowWidth();
    const { statusStudentBlock } = useStudentStatus();

    const [electivesFormated, setElectivesFormated] = useState([]);
    const [hasElectiveSubject, setHasElectiveSubject] = useState(false);
    const [disableLast, setDisableLast] = useState(false);

    useEffect(() => {
        modulesHandler(modules);
    }, [modules]);

    useEffect(() => {
        setDisableLast(
            hasElectiveSubject
                ? !checkAllDoneBefore(modules) || electivesFormated.length === 0
                : !checkAllDoneBefore(modules)
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasElectiveSubject]);

    const handleClickSurvey = (hash, module) => {
        if (!!module?.onStudy) {
            history.push(`/view/survey/${hash}`);
        }
    };

    const handleClick = (hash) => {
        history.push(`/curso/${hash}?id=${params.id}&origin=${origin}`);
    };

    const ModuleDescription = (props) => {
        const { description } = props;

        return (
            <DescriptionWrapper>
                <Text
                    fontSize={screenWidth > 1024 ? '16px' : '14px'}
                    fontWeight="400"
                >
                    {description}
                </Text>
            </DescriptionWrapper>
        );
    };

    // Verifica que todo lo anterior al último curso
    const checkAllDoneBefore = (array) => {
        let allDone = true;
        array.forEach((module) => {
            // Si no es encuesta y tiene progreso diferente a 100 = false
            // Si es encuesta y no está hecha = false
            // Y no tiene que ser tipo "Bloque Integrador"
            // si es main_elective y tiene progreso diferente a 100 = false

            if (
                (!module?.survey &&
                    module?.type !== 'Bloque Integrador' &&
                    module?.progress !== 100 &&
                    !Array.isArray(module.electives) &&
                    module.category === 'obligatoria' &&
                    !module.selected_from_elective) ||
                (module?.survey && !module?.survey?.done) ||
                (!!module.main_elective &&
                    module.main_elective !== 'not_electiva' &&
                    module.progress !== 100)
            ) {
                allDone = false;
                return allDone;
            }
        });
        return allDone;
    };

    const modulesHandler = (modules) => {
        const fileteredModules = modules.filter((modules) => {
            return !!modules.selected_from_elective;
        });
        setElectivesFormated(fileteredModules);

        for (const module of modules) {
            if (module.electives.length >= 1) {
                setHasElectiveSubject(true);
            }
        }
    };

    return (
        <Wrapper>
            <TitleWrapper>
                <Text fontSize="20px" fontWeight="500">
                    Bloques que componen esta asignatura:
                </Text>
                <FakeArrowDownward />
            </TitleWrapper>
            <Container>
                <OrnamentLine />
                {modules?.map((module, i) => (
                    <>
                        {(!module?.category === 'electiva' ||
                            module?.main_elective) && (
                                <ModulesWrapper key={i}>
                                    <ModuleCheck>
                                        {!module.selected_from_elective && (
                                            <IconWrapper>
                                                {module?.progress !== 0 ||
                                                    module?.survey?.done ||
                                                    (Array.isArray(module.electives) && module.electives.some(elective => elective?.progress !== 0)) ? (
                                                    <CheckCircle
                                                        fontSize={
                                                            screenWidth > 768
                                                                ? 'medium'
                                                                : 'large'
                                                        }
                                                    />
                                                ) : module.category !== 'electiva' ? (
                                                    <RadioButtonUnchecked
                                                        fontSize={
                                                            screenWidth > 768
                                                                ? 'medium'
                                                                : 'large'
                                                        }
                                                    />
                                                ) : (
                                                    <CircleElective>
                                                        <Icon icon="tdesign:gesture-click" width="25px" height="25px" />
                                                    </CircleElective>
                                                )}
                                            </IconWrapper>
                                        )}
                                    </ModuleCheck>
                                    <StateWrapper>
                                        {module?.survey ? (
                                            module?.survey?.done ? (
                                                <>
                                                    <CheckCircleOutline />
                                                    <Text
                                                        color="#616161"
                                                        fontSize={
                                                            screenWidth > 1024
                                                                ? '16px'
                                                                : '14px'
                                                        }
                                                        fontWeight="500"
                                                    >
                                                        Completaste la encuesta
                                                    </Text>
                                                </>
                                            ) : (
                                                <>
                                                    <Error />
                                                    <Text
                                                        color="#616161"
                                                        fontSize={
                                                            screenWidth > 1024
                                                                ? '16px'
                                                                : '14px'
                                                        }
                                                        fontWeight="500"
                                                    >
                                                        Recuerda completar la
                                                        encuesta para continuar con
                                                        tu cursado.
                                                    </Text>
                                                </>
                                            )
                                        ) : (
                                            <>
                                                {module.category !== 'electiva' &&
                                                    !module.selected_from_elective && (
                                                        <>
                                                            {module?.rework_activities && (
                                                                <ReworkActivitiesChip>
                                                                    <Icon
                                                                        icon="mingcute:alert-line"
                                                                        width="24px"
                                                                        height="24px"
                                                                    />
                                                                    Tienes
                                                                    actividades por
                                                                    rehacer
                                                                </ReworkActivitiesChip>
                                                            )}
                                                            <Text
                                                                color="#616161"
                                                                fontSize={
                                                                    screenWidth >
                                                                        1024
                                                                        ? '16px'
                                                                        : '14px'
                                                                }
                                                                fontWeight="700"
                                                            >
                                                                Estado:{' '}
                                                            </Text>
                                                            <State
                                                                state={
                                                                    module?.progress ===
                                                                        100
                                                                        ? 'finished'
                                                                        : module?.progress ===
                                                                            0
                                                                            ? 'to_begin'
                                                                            : 'on_study'
                                                                }
                                                            >
                                                                {module?.progress ===
                                                                    100
                                                                    ? 'Finalizado'
                                                                    : module?.progress ===
                                                                        0
                                                                        ? 'Por iniciar'
                                                                        : 'En curso'}
                                                            </State>
                                                        </>
                                                    )}
                                            </>
                                        )}
                                    </StateWrapper>
                                    {!module?.survey ? (
                                        !!module?.electives ? (
                                            <ElectiveSubject
                                                electives={module?.electives}
                                                resetData={resetData}
                                                handleClick={handleClick}
                                                electivesFormated={
                                                    electivesFormated
                                                }
                                                disabled={
                                                    demo === 'DEMO'
                                                        ? !module?.module_demo
                                                        : false
                                                }
                                            />
                                        ) : (
                                            <>
                                                {module?.category !== 'electiva' &&
                                                    !module?.selected_from_elective && (
                                                        <SubjectAccordion
                                                            key={i}
                                                            data={module}
                                                            summary={
                                                                CourseModuleType
                                                            }
                                                            details={
                                                                ModuleDescription
                                                            }
                                                            handleClick={
                                                                handleClick
                                                            }
                                                            isCompletedBg={
                                                                module?.survey
                                                            }
                                                            borderRadius="20px"
                                                            size={module?.survey}
                                                            description={
                                                                module?.description
                                                            }
                                                            statusBlock={
                                                                module.progress ===
                                                                0 &&
                                                                statusStudentBlock?.studentStatus ===
                                                                'Bloqueado'
                                                            }
                                                            reasonBlocked={
                                                                statusStudentBlock
                                                                    ?.blockDetail
                                                                    ?.reason
                                                            }
                                                            disabled={
                                                                (demo === 'DEMO'
                                                                    ? !module?.module_demo
                                                                    : false) ||
                                                                (module.progress ===
                                                                    0 &&
                                                                    statusStudentBlock?.studentStatus ===
                                                                    'Bloqueado')
                                                            }
                                                            height={
                                                                module.type ===
                                                                    'Bloque Integrador'
                                                                    ? '155px'
                                                                    : '120px'
                                                            }
                                                            detailsPadding="1rem 0 0 2.3rem"
                                                            disabledLast={
                                                                disableLast
                                                            }
                                                            hasElectiveSubject={
                                                                hasElectiveSubject
                                                            }
                                                            hasDescription={!!module?.description}
                                                        />
                                                    )}
                                            </>
                                        )
                                    ) : (
                                        <SubjectAccordion
                                            key={i}
                                            data={module}
                                            summary={SurveyModuleType}
                                            handleClick={handleClickSurvey}
                                            size={module?.survey}
                                            disabled={
                                                demo === 'DEMO'
                                                    ? !module?.module_demo
                                                    : false
                                            }
                                            borderRadius="20px"
                                            height="250px"
                                            detailsPadding="1rem 0 0 2.3rem"
                                            disabledLast={disableLast}
                                        />
                                    )}
                                </ModulesWrapper>
                            )}
                    </>
                ))}
            </Container>
        </Wrapper>
    );
};

export default SubjectFormativeModules;

function CourseModuleType(props) {
    const { data, handleClick, disabledLast, hasElectiveSubject } = props;
    const screenWidth = useWindowWidth();
    const hash = encode.encode(data?.id + '', 'base64');

    return (
        <ModuleHeaderWrapper type="course">
            <TextWrapper type="course">
                <Text
                    fontSize={
                        screenWidth > 1024 || screenWidth < 768
                            ? '14px'
                            : '12px'
                    }
                    fontWeight="500"
                    color="#616161"
                >
                    {data?.type}
                </Text>
                <ModuleName title={data?.publication_name}>
                    {data?.publication_name}
                </ModuleName>
            </TextWrapper>
            <ProgressWrapper>
                <ModuleLinearProgress
                    color="secondary"
                    variant="determinate"
                    value={Math.trunc(data?.progress)}
                />
                <Text
                    fontSize={
                        screenWidth > 1024 || screenWidth < 768
                            ? '14px'
                            : '12px'
                    }
                    fontWeight="400"
                    color="#222222"
                >
                    {data?.progress}%
                </Text>
            </ProgressWrapper>
            <ButtonWrapper>
                <FakeButton
                    variant="contained"
                    onClick={() => handleClick(hash, data?.id)}
                    disabled={
                        data?.type === 'Bloque Integrador' && disabledLast
                    }
                >
                    {data?.progress === 100 ? 'Volver a ver' : 'Cursar'}
                </FakeButton>
                <div></div>
            </ButtonWrapper>
            {data?.type === 'Bloque Integrador' && disabledLast && (
                <InfoDisabled>
                    <Icon icon="octicon:info-24" width="24px" height="24px" />
                    <label>
                        Para habilitar este bloque{' '}
                        <b>
                            {
                                hasElectiveSubject
                                    ? "deberás realizar la encuesta de satisfacción y finalizar el cursado de la electiva principal."
                                    : "deberás finalizar los bloques anteriores y responder la encuesta de satisfacción."
                            }
                        </b>
                    </label>
                </InfoDisabled>
            )}
        </ModuleHeaderWrapper>
    );
}

function SurveyModuleType(props) {
    const { data, handleClick } = props;

    const survey = {
        link: data?.survey?.link || false,
        id: data?.survey?.id || false,
        done: data?.survey?.done || false,
    };

    // A veces al encodear en base 64 el hash queda con un "/" que no es permitido en la URL, entonces lo reemplazamos por "*"
    const hashSurvey = encode.encode(JSON.stringify(survey), 'base64').replace(/\//g, '*');

    return (
        <ModuleHeaderWrapper type="survey">
            <ImageWrapper>
                <SurveyImage src={surveyImage} />
            </ImageWrapper>
            <TextWrapper type="survey">
                <h3><b>{data?.survey?.name}</b></h3>
                <h4>Tu opinión es muy importante <br /> para seguir mejorando</h4>
                <p>¿Nos ayudas completando una breve encuesta?</p>
            </TextWrapper>
            <ButtonWrapper type="survey">
                <FakeButton
                    variant="contained"
                    onClick={() => handleClick(hashSurvey, data)}
                >
                    Responder encuesta
                </FakeButton>
            </ButtonWrapper>
            <BackgroundEllipse />
            <SuggestionInfo>
                <IconContainer>
                    <Icon
                        icon="fa6-regular:lightbulb"
                        width="24px"
                        height="24px"
                    />
                </IconContainer>
                <label>
                    Te sugerimos <b>realizar la encuesta</b> cuando ya tengas
                    una <b>visión integral</b> sobre el{' '}
                    <b>profesor tutor y el contenido de la asignatura.</b>
                </label>
            </SuggestionInfo>
        </ModuleHeaderWrapper>
    );
}

const Wrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 120px;
    height: 100%;

    @media screen and (max-width: 768px) {
        gap: 8rem;
    }
`;

const Container = styled.div`
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 6rem;

    @media (width < 768px) {
        gap: 6rem;
    }
`;

const TitleWrapper = styled.div`
    margin-left: 40px;

    @media screen and (max-width: 768px) {
        display: flex;
        margin-left: 0;
        flex-direction: column;
        align-items: center;
        padding: 0 1rem;
        text-align: center;
        gap: 3rem;
    }
`;

const FakeArrowDownward = styled(ArrowDownward)`
    font-size: 2.4rem;
    display: none;
    @media screen and (max-width: 768px) {
        display: block;
    }
`;

const ModulesWrapper = styled.div`
    box-sizing: border-box;
    position: relative;
    display: grid;
    grid-template-columns: 1fr 18fr;
    align-items: center;

    @media screen and (max-width: 1440px) {
        grid-template-columns: 1fr 14fr;
    }

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`;

const ModuleCheck = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 50%;

    @media screen and (max-width: 768px) {
        position: absolute;
        top: -3.7rem;
    }
`;

const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: #b31d15;
    background-color: #f9f9f9;
    padding: 3px 0;

    svg {
        color: #b31d15;
        @media screen and (max-width: 768px) {
            font-size: 2.5rem;
        }
    }
`;

const CircleElective = styled.div`
    width: 42px;
    height: 42px; 
    border-radius: 100px; 
    border: 2px solid #B31D15; 
    margin-left: -8px; 
    display: flex; 
    align-items: center; 
    justify-content: center;
`

const OrnamentLine = styled.span`
    width: 2px;
    position: absolute;
    min-height: calc(100% - 7.7rem);
    margin: auto;
    left: 14px;
    top: 50%;
    visibility: visible;
    transform: translate(-50%, -50%);
    background-color: #b31d15;

    @media screen and (max-width: 768px) {
        left: 16px;
        min-height: calc(100% - 19rem);
        display: none;
    }
`;

const StateWrapper = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    gap: 12px;
    width: fit-content;
    top: -3.4rem;
    right: 0;

    svg {
        color: #b31d15;
    }
`;

const State = styled.span`
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.06px;
    padding: 0.4rem;
    border-radius: 8px;

    color: ${(p) =>
        p.state === 'finished'
            ? '#1E8065'
            : p.state === 'to_begin'
                ? '#C29F43'
                : '#B31D15'};

    background-color: ${(p) =>
        p.state === 'finished'
            ? 'rgba(30, 128, 101, .15)'
            : p.state === 'to_begin'
                ? 'rgba(194, 159, 67, .15)'
                : 'rgba(179, 29, 21, .15)'};
`;

const ModuleHeaderWrapper = styled.div`
    position: relative;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 36% 36% 28%;
    width: 100%;
    margin-bottom: 2rem;

    @media screen and (max-width: 1440px) {
        grid-template-columns: 1fr 0.5fr 1fr;
    }

    @media screen and (max-width: 1024px) {
        grid-template-columns: 1.5fr 1fr 1fr;
    }

    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        gap: 2.5rem;
        padding: 1.5rem 0;
    }
`;

const ReworkActivitiesChip = styled.div`
    min-height: 32px;
    font-size: 12px;
    font-weight: 700;
    padding: 0 1rem;
    border-radius: 100px;
    background-color: #fff;
    color: #b31d15;
    border: 1px solid #b31d15;
    text-align: center;
    display: flex;
    align-items: center;
    gap: 4px;
    box-shadow: 0px 8px 24px -4px #18274b14, 0px 6px 12px -6px #18274b1f;
    z-index: 10;

    svg {
        color: #b31d15;
    }
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: ${(p) => (p.type === 'survey' ? '1rem' : '5px')};
    white-space: ${(p) => (p.type === 'survey' ? 'none' : 'nowrap')};
    overflow-wrap: break-word;
    padding-left: 20px;
    max-height: 100%;
    width: ${(p) => (p.type === 'survey' ? 'fit-content' : '370px')};

    h3 {
        font-size: 22px;
        font-weight: 400;
        color: #B31D15;
        max-width: 380px;
    }

    h4 {
        font-size: 16px;
        font-weight: 400;
        max-width: 380px;
        color: #222;
    }

    p {
        font-size: 16px;
        font-weight: 700;
        max-width: 380px;
        color: #222;
    }

    @media screen and (max-width: 1440px) {
        width: ${(p) => (p.type === 'survey' ? '260px' : '250px')};
    }

    @media screen and (max-width: 1024px) {
        width: ${(p) => (p.type === 'survey' ? '220px' : '220px')};
    }

    @media screen and (max-width: 768px) {
        min-width: ${(p) => (p.type === 'survey' ? '100%' : '90%')};
        padding-left: 0;
    }
`;

const ModuleName = styled.p`
    font-size: 20px;
    font-weight: 700;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
    color: #b31d15;

    @media screen and (max-width: 1024px) {
        font-size: 16px;
    }

    @media screen and (max-width: 768px) {
        font-size: 18px;
    }
`;

const ProgressWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-start;
    width: 100%;

    @media screen and (max-width: 768px) {
        width: 90%;
    }
`;

const ModuleLinearProgress = styled(LinearProgress)`
    width: 350px;
    height: 7px;
    border-radius: 8px;
    background-color: #eaeaea;

    @media screen and (max-width: 1440px) {
        width: 250px;
    }

    @media screen and (max-width: 1024px) {
        width: 200px;
    }

    @media screen and (max-width: 768px) {
        width: 100%;
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    z-index: 5;

    @media screen and (max-width: 1440px) {
        justify-content: center;
    }

    @media screen and (max-width: 768px) {
        width: 100%;
    }
`;

const FakeButton = styled(Button)`
    width: 200px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.014px;
    border-radius: 10px;
    padding: 0.55rem 0;

    @media screen and (max-width: 1024px) {
        width: 150px;
    }

    @media screen and (max-width: 768px) {
        width: 100%;
    }
`;

const DescriptionWrapper = styled.div`
    width: 75%;
    padding-bottom: 3rem;
`;

const ImageWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

const SurveyImage = styled.img`
    width: 280px;
    height: 154px;

    @media screen and (max-width: 1024px) {
        width: 200px;
        height: 114px;
    }

    @media screen and (max-width: 768px) {
        width: 280px;
        height: 154px;
    }

    @media screen and (max-width: 375px) {
        width: 200px;
        height: 114px;
    }
`;

const BackgroundEllipse = styled.span`
    position: absolute;
    width: 500px;
    clip-path: ellipse(12rem 80% at 80% bottom);
    height: 100%;
    right: 0;
    border-radius: 0 20px 20px 0;
    background-color: #f9f9f9;
    z-index: 0;
    top: 0;
    right: 0;
    margin: auto;
    z-index: 1;

    @media screen and (max-width: 1440px) {
        clip-path: ellipse(12rem 80% at 90% bottom);
    }

    @media screen and (max-width: 768px) {
        display: none;
    }
`;

const SuggestionInfo = styled.div`
    width: 100%;
    position: absolute;
    bottom: -3rem;
    left: 7rem;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 8px;

    @media (width < 1600px) {
        left: 3rem;
    }

    @media (width < 768px) {
        left: 0rem;
    }
`;

const IconContainer = styled.div`
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background-color: #f9f5ec;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #c29f43;
`;

const InfoDisabled = styled.div`
    position: absolute;
    bottom: -2.5rem;
    left: 2rem;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 8px;

    svg {
        color: #c29f43;
    }

    label {
        color: #616161;
        font-size: 14px;
        font-weight: 400;

        b {
            font-weight: 700;
        }
    }
`;
